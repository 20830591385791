export interface LoadingStoreState {
  isLoading: boolean | null
}
export const useLoadingStore = defineStore('Loading', {
  state: () => ({ isLoading: null }) as LoadingStoreState,
  getters: {},
  actions: {
    setLoading(state: boolean) {
      this.isLoading = state
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot))
}
