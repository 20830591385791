import { Cluster } from './Cluster'
import { Item } from './Queue'

export class Clusters {
  public clusters: Cluster[]

  constructor() {
    this.clusters = []
  }

  public addElement(element: HTMLElement) {
    const target: HTMLElement = element.closest('[data-animation-cluster]') || element

    const cluster: Cluster | undefined = this.clusters.find((cluster: Cluster) => target === cluster.target)

    if (cluster) {
      cluster.addElement(element)
      return undefined
    }

    this.clusters.push(new Cluster(target, element))

    return target
  }

  public removeElement(element: HTMLElement) {
    const target = this.clusters.reduce((target: HTMLElement | undefined, cluster) => {
      return cluster.removeElement(element) || target
    }, undefined)

    if (target) {
      this.clusters = this.clusters.filter((cluster) => target !== cluster.target)
    }

    return target
  }

  public getItemsToAnimate(visibleTargets: HTMLElement[]) {
    const clusters: Cluster[] = this.clusters.filter((cluster: Cluster) => visibleTargets.includes(cluster.target))

    return Clusters.createItemsToAnimate(clusters)
  }

  private static createItemsToAnimate(clusters: Cluster[]) {
    const items: Item[] = []
    let currentIndex: number = 0
    clusters.forEach((cluster: Cluster) => {
      items.push(
        ...cluster.items.map((item, index: number) => ({
          ...item,
          delayMultiplier: currentIndex + index,
        })),
      )

      currentIndex += (cluster.target.dataset.animateClusterIsolated || '') === 'false' ? cluster.items.length : 1
    })
    return items
  }
}
