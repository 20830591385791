import routerOptions0 from "/usr/src/app/src/app/router.options.ts";
import routerOptions1 from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions1,
...routerOptions0,
}