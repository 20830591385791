<script lang="ts" setup>
import { ImgHTMLAttributes } from 'vue'
import { MediaImageFragment } from '~/api/graphql/generated'

type Size = [number | null, string][]

const imageSizes = {
  teaser: [
    [768, '100vw'],
    [1024, '50vw'],
    [null, '520px'],
  ] as Size,
  'teaser-full': [[null, '100vw']] as Size,
  'teaser-main-menu': [[null, '200px']] as Size,
  employee: [
    [1024, '300px'],
    [null, '400px'],
  ] as Size,
  'employee-teaser': [[null, '300px']] as Size,
  'teaser-single': [
    [1024, '100vw'],
    [1440, '50vw'],
    [null, '1100px'],
  ] as Size,
  'author-thumbnail': [[null, '120px']] as Size,
  'sidebar-logo': [[null, '150px']] as Size,
}

type ImageSizes = keyof typeof imageSizes

interface Slice {
  width: number
  height: number
  url: string
}

const getSizes = (type: ImageSizes) =>
  imageSizes[type]
    .reduce((acc, item) => {
      if (!item[0]) return `${acc}${item[1]}, `
      return `${acc}(max-width: ${item[0] - 1}px) ${item[1]}, `
    }, '')
    .slice(0, -2)

const getSrcset = (data: Slice[]) =>
  data.reduce((acc: string, slice: Slice): string => `${acc}${slice.url} ${slice.width}w,`, '').slice(0, -1)

const props = withDefaults(
  defineProps<{
    entity: MediaImageFragment
    type: ImageSizes
  }>(),
  {
    type: 'teaser',
  },
)

type ImageData = MediaImageFragment['fieldMediaImage']

const getAttrs = (imageData: ImageData) => {
  const original = {
    width: imageData?.width || 0,
    height: imageData?.height || 0,
    url: imageData?.url || '',
  }

  const keys = ['small', 'smallRetina', 'medium', 'mediumRetina', 'large', 'largeRetina', 'xlarge', 'xxlarge'] as const
  const data = [
    ...keys.map((key) => imageData?.[key] as Slice).filter((slice) => slice?.width !== original.width),
    3000 > original.width ? original : undefined,
  ].filter(Boolean)

  return {
    class: 'dp-image',
    width: original.width,
    height: original.height,
    src: data[0].url || '',
    srcset: getSrcset(data),
    sizes: getSizes(props.type),
    alt: imageData?.alt || '',
    loading: 'lazy',
  }
}

const attrs = computed(() => getAttrs(props.entity.fieldMediaImage))
</script>
<template>
  <img v-bind="attrs as ImgHTMLAttributes" />
</template>

<style scoped lang="scss">
img {
  object-fit: cover;
}
</style>
