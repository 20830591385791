import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'

export default defineNuxtPlugin(() => {
  const { clients } = useApollo()
  const persistedQueriesLink = createPersistedQueryLink({ sha256 })
  const httpLink = clients!.default.link

  // create the new link
  const newLink = persistedQueriesLink.concat(httpLink)

  // set the new link
  clients!.default.setLink(newLink)
})
