<script lang="ts" setup>
const store = useLoadingStore()
const isLoading = computed(() => store.isLoading)

const app = useNuxtApp()

app.hook('page:start', () => {
  if (!store.isLoading) {
    store.setLoading(true)
  }
})

app.hook('page:finish', () => {
  if (store.isLoading) {
    store.setLoading(false)
  }
})
</script>
<template>
  <div v-show="isLoading" class="loading-overlay">
    <div class="skeleton-loader main container grid">
      <div class="image-placeholder anim" />
      <div class="content-placeholder t-11 ds-10">
        <div class="breadcrumbs lines lines-small">
          <div class="line line-medium anim" />
        </div>
        <div class="title lines lines-large">
          <div class="line anim" />
          <div class="line line-short anim" />
        </div>
        <div class="intro lines lines-medium">
          <div class="line anim" />
          <div class="line anim" />
          <div class="line anim" />
          <div class="line anim" />
          <div class="line line-short anim" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading-overlay {
  /* Define your loading overlay styles here */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c-white);
  z-index: 50;
}
.skeleton-loader {
  @include fluid(--overlap, 48px, 128px);

  .image-placeholder {
    position: relative;
    overflow: hidden;
    @include fluid(height, 380px, 620px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }

  .content-placeholder {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: grid;
    gap: 40px var(--gap);
    grid-template-columns: 100%;

    @include breakpoint(t) {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    @include breakpoint(ds) {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    margin-top: calc(-1 * var(--overlap));
    grid-column-start: 1;
    @include fluid(padding-top, 24px, 40px);
    background-color: var(--c-white);

    .breadcrumbs,
    .back,
    .title,
    .subtitle,
    .intro {
      grid-column-start: 1;
      grid-column-end: -1;
      @include breakpoint(t) {
        grid-column-start: 2;
      }
      @include breakpoint(tl) {
        grid-column-start: 1;
      }
      @include breakpoint(ds) {
        grid-column-start: 2;
      }
    }
    .intro {
      @include breakpoint(tl) {
        grid-column-end: -2;
      }
    }

    .breadcrumbs,
    .title,
    .subtitle,
    .intro {
      @include breakpoint(t) {
        grid-column-end: -2;
      }
    }
  }

  .lines {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    &-small {
      --height: 20px;
      --gap: 5px;
    }
    &-medium {
      --height: 30px;
      --gap: 10px;
    }
    &-large {
      --height: 70px;
      --gap: 15px;
    }

    .line {
      width: 100%;
      height: var(--height);
      background-color: rgba(0, 0, 0, 0.12);
      border-radius: calc(var(--height) / 8);
      &-short {
        width: 50%;
      }
      &-medium {
        width: 75%;
      }
    }
  }
}

.anim {
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation: waves 3s infinite;
    content: '';
  }

  @keyframes waves {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
