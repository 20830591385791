import type { RouterConfig } from '@nuxt/schema'
import extraRoutesShop from '~/app/extraRoutesShop'
import { defaultLocale } from '~/i18n/config'

const OFFSET_EXTRA = 50

const getTransformY = (element: HTMLElement) => new DOMMatrix(window.getComputedStyle(element).transform).m42

const getTarget = async (hash: string, x: number): Promise<{ element: HTMLElement | undefined; selector: string }> => {
  const fromId = document.querySelector<HTMLElement>(hash)
  if (fromId) {
    return {
      element: fromId,
      selector: hash,
    }
  }

  const fromDataId = document.querySelector<HTMLElement>(`[data-id="${hash.substring(1)}"]`)
  if (fromDataId) {
    return {
      element: fromDataId,
      selector: `[data-id="${hash.substring(1)}"]`,
    }
  }

  return new Promise((resolve) => {
    if (x > 50) {
      // Stop after 50 frames
      return resolve({
        element: undefined,
        selector: '',
      })
    }
    setTimeout(() => {
      resolve(getTarget(hash, ++x || 1))
    }, 100)
  })
}

const getOffset = (element: HTMLElement) => {
  const currentPosition = window.scrollY
  const transformY = getTransformY(element)
  const nextPosition = (element && element.getBoundingClientRect().top - transformY + window.scrollY) || 0

  const headerHeight = document.querySelector<HTMLElement>('.header')?.offsetHeight || 0

  const offset = currentPosition < nextPosition ? OFFSET_EXTRA + transformY : OFFSET_EXTRA + transformY + headerHeight

  return offset
}

export default <RouterConfig>{
  routes: (existingRoutes) => {
    const routes = [
      ...existingRoutes,
      ...extraRoutesShop.flatMap((route: any) => {
        return route.translations.map((translation: any) => {
          return {
            component: () => import('~/pages/[...all].vue').then((r) => r.default || r),
            path:
              translation.language === defaultLocale ? translation.url : `/${translation.language}${translation.url}`,
            name: `shop-${route.name}___${translation.language}`,
            meta: {
              middleware: 'get-layout',
            },
          }
        })
      }),
    ]

    return routes
  },
  scrollBehavior: async (to, from, savedPosition) => {
    if (to.query.scroll === 'false') {
      return null
    }

    if (to.hash) {
      const { element, selector } = await getTarget(to.hash, 0)

      if (!element) return null

      const offset = getOffset(element)

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: selector,
            top: offset,
            behavior: 'smooth',
          })
        }, 10)
      })
    }

    const position = {
      top: typeof savedPosition !== 'object' || savedPosition === null ? 0 : savedPosition.top,
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position)
      }, 500)
    })
  },
}
