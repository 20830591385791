import { getIsoCode } from '~/i18n/helpers'

const locales = ['en', 'fr', 'it', 'de'] as const
const LOCALES = ['EN', 'FR', 'IT', 'DE'] as const
export type LanguageCode = (typeof locales)[number]
export type LanguageCODE = (typeof LOCALES)[number]

export const useLocale = () => {
  const { availableLocales, locale } = useI18n()
  const router = useRouter()
  const { getAvailableLanguages } = useInternationalization()

  const languageCode = computed(() => locale.value as LanguageCode)
  const languageCODE = computed(() => locale.value.toUpperCase() as LanguageCODE)

  const getLanguageId = async () => {
    const { data } = await useAsyncData('languages', async () => {
      return await getAvailableLanguages()
    })

    if (data.value?.elements.length && router.currentRoute.value.name) {
      const isoCode = getIsoCode(availableLocales, router.currentRoute.value.name as string)
      return data.value?.elements.find((language: any) => language.translationCode.code === isoCode)?.id
    }

    return undefined
  }

  return {
    languageCode,
    languageCODE,
    getLanguageId,
  }
}
