import { transitionEndEvent, getTransitionDuration } from './utils'

export interface Item {
  element: HTMLElement
  delay: number
  delayMultiplier: number
}

export class Queue {
  private items: Item[]
  private baseDelay: number

  constructor(items: Item[], baseDelay: number) {
    this.baseDelay = baseDelay
    this.items = items

    this.cleanup = this.cleanup.bind(this)
  }

  public process() {
    this.items.map(this.processItem.bind(this))
  }

  private processItem(item: Item) {
    const { element, delay, delayMultiplier } = item

    element.style.transitionDelay = `${delay + delayMultiplier * this.baseDelay}ms`
    element.addEventListener(transitionEndEvent, this.cleanup)

    element.classList.add('animate')
  }

  private cleanup(e: TransitionEvent) {
    const element: HTMLElement = e.target as HTMLElement

    if (!element.classList.contains('animate')) {
      return
    }

    const elapsedTime: string = `${e.elapsedTime * 1000}`

    if (parseInt(elapsedTime, 10) === getTransitionDuration(element)) {
      element.classList.remove(...['queued', 'animate'])
      element.style.transitionDelay = ''

      element.removeEventListener(transitionEndEvent, this.cleanup)
    }
  }
}
