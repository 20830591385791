<script lang="ts" setup>
import { kebabCase } from 'scule'

const props = defineProps<{
  large?: boolean
  wide?: boolean
  name?: string
  parentName?: string
}>()

const element = ref<HTMLElement | null>(null)
defineExpose({ element })

const parentName = props.parentName || getCurrentInstance()?.parent?.type.__name || ''
const sectionName = props.name ? `section-${props.name}` : kebabCase(parentName)
</script>
<template>
  <section
    ref="element"
    :class="['section', sectionName, { 'section-large': large }, { box: wide }, { container: !wide }, { grid: !wide }]"
  >
    <slot />
  </section>
</template>
