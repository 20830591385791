import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp) => {
  if (process.server) return
  const config = useRuntimeConfig()
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: config.public.recaptchaPublic,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
})
