export interface LanguageSwitcherLink {
  locale: string
  path: string
}

export type LanguageSwitcherStoreState = {
  drupal: {
    links: LanguageSwitcherLink[]
  }
  shopware: {
    entityId: string
  }
}

export const useLanguageSwitcherStore = defineStore('LanguageSwitcher', {
  state: () => ({ drupal: { links: [] }, shopware: { entityId: '' } }) as LanguageSwitcherStoreState,
  getters: {},
  actions: {
    setDrupal(links: LanguageSwitcherLink[]) {
      this.drupal.links = links
    },
    setShopware(entityId: string) {
      this.shopware.entityId = entityId
    },
    clear() {
      this.drupal.links = []
      this.shopware.entityId = ''
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLanguageSwitcherStore, import.meta.hot))
}
