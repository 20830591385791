export const locales = [
  {
    code: 'de',
    iso: 'de-DE',
    file: 'de-DE.json',
    name: 'Deutsch',
    isCatchallLocale: true,
  },
  {
    code: 'en',
    iso: 'en-GB',
    file: 'en-GB.json',
    name: 'English',
  },
  {
    code: 'fr',
    iso: 'fr-FR',
    file: 'fr-FR.json',
    name: 'Français',
  },
  {
    code: 'it',
    iso: 'it-IT',
    file: 'it-IT.json',
    name: 'Italiano',
  },
]

export const defaultLocale = 'de'
