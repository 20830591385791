interface ClusterItem {
  element: HTMLElement
  delay: number
}

export class Cluster {
  public target: HTMLElement
  public items: ClusterItem[]

  constructor(target: HTMLElement, element: HTMLElement) {
    this.target = target
    this.items = [Cluster.createItem(element)]
  }

  public addElement(element: HTMLElement) {
    this.items.push(Cluster.createItem(element))
  }

  public removeElement(element: HTMLElement) {
    this.items = this.items.filter((item) => element !== item.element)

    if (this.items.length) {
      return undefined
    }

    return this.target
  }

  private static createItem(element: HTMLElement) {
    element.classList.add('queued')
    return {
      element,
      delay: parseInt(element.dataset.animationDelay || '', 10) || 0,
    }
  }
}
