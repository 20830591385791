import { default as _91_46_46_46all_931CtIhcvXbeMeta } from "/usr/src/app/src/pages/[...all].vue?macro=true";
import { default as healthcheck5osqMiipu0Meta } from "/usr/src/app/src/pages/healthcheck.vue?macro=true";
import { default as _91_46_46_46id_93gEgcWcJiWdMeta } from "/usr/src/app/src/pages/preview/[...id].vue?macro=true";
import { default as _91id_93HvA7ftD3JTMeta } from "/usr/src/app/src/pages/registration/conference/[id].vue?macro=true";
import { default as _91id_93WuzS8DYIkNMeta } from "/usr/src/app/src/pages/registration/module/[id].vue?macro=true";
import { default as _91_46_46_46all_93tw3bE7BUTFMeta } from "/usr/src/app/src/pages/registration/seminar/[...all].vue?macro=true";
import { default as searchtAu4maexkaMeta } from "/usr/src/app/src/pages/search.vue?macro=true";
import { default as _91_46_46_46slug_93jFjHtL6hBMMeta } from "/usr/src/app/src/pages/shop/[...slug].vue?macro=true";
import { default as addressesGii3MgdQREMeta } from "/usr/src/app/src/pages/shop/account/addresses.vue?macro=true";
import { default as indexkBojIwOurAMeta } from "/usr/src/app/src/pages/shop/account/index.vue?macro=true";
import { default as _91_46_46_46id_93CBWMBdsza2Meta } from "/usr/src/app/src/pages/shop/account/orders/[...id].vue?macro=true";
import { default as indexjJhgTQJMyXMeta } from "/usr/src/app/src/pages/shop/account/orders/index.vue?macro=true";
import { default as payment55xEq6C3FiMeta } from "/usr/src/app/src/pages/shop/account/payment.vue?macro=true";
import { default as profileMJiDVBQkIwMeta } from "/usr/src/app/src/pages/shop/account/profile.vue?macro=true";
import { default as recoveryV5t4Aa0ptkMeta } from "/usr/src/app/src/pages/shop/account/recovery.vue?macro=true";
import { default as cart9lyjLQlHAeMeta } from "/usr/src/app/src/pages/shop/cart.vue?macro=true";
import { default as indexgj9RPAfLx1Meta } from "/usr/src/app/src/pages/shop/checkout/index.vue?macro=true";
import { default as indexL8InZPvytEMeta } from "/usr/src/app/src/pages/shop/checkout/order/[id]/index.vue?macro=true";
import { default as indexQ1MFfh1bNmMeta } from "/usr/src/app/src/pages/shop/index.vue?macro=true";
import { default as loginm0S2q7JMiMMeta } from "/usr/src/app/src/pages/shop/login.vue?macro=true";
import { default as register9LJ5bkmlgdMeta } from "/usr/src/app/src/pages/shop/register.vue?macro=true";
import { default as searchfwjFqkC70VMeta } from "/usr/src/app/src/pages/shop/search.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_931CtIhcvXbeMeta?.name ?? "all___de",
    path: _91_46_46_46all_931CtIhcvXbeMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_931CtIhcvXbeMeta || {},
    alias: _91_46_46_46all_931CtIhcvXbeMeta?.alias || [],
    redirect: _91_46_46_46all_931CtIhcvXbeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_931CtIhcvXbeMeta?.name ?? "all___en",
    path: _91_46_46_46all_931CtIhcvXbeMeta?.path ?? "/en/:all(.*)*",
    meta: _91_46_46_46all_931CtIhcvXbeMeta || {},
    alias: _91_46_46_46all_931CtIhcvXbeMeta?.alias || [],
    redirect: _91_46_46_46all_931CtIhcvXbeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_931CtIhcvXbeMeta?.name ?? "all___fr",
    path: _91_46_46_46all_931CtIhcvXbeMeta?.path ?? "/fr/:all(.*)*",
    meta: _91_46_46_46all_931CtIhcvXbeMeta || {},
    alias: _91_46_46_46all_931CtIhcvXbeMeta?.alias || [],
    redirect: _91_46_46_46all_931CtIhcvXbeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_931CtIhcvXbeMeta?.name ?? "all___it",
    path: _91_46_46_46all_931CtIhcvXbeMeta?.path ?? "/it/:all(.*)*",
    meta: _91_46_46_46all_931CtIhcvXbeMeta || {},
    alias: _91_46_46_46all_931CtIhcvXbeMeta?.alias || [],
    redirect: _91_46_46_46all_931CtIhcvXbeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: healthcheck5osqMiipu0Meta?.name ?? "healthcheck___de",
    path: healthcheck5osqMiipu0Meta?.path ?? "/healthcheck",
    meta: healthcheck5osqMiipu0Meta || {},
    alias: healthcheck5osqMiipu0Meta?.alias || [],
    redirect: healthcheck5osqMiipu0Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: healthcheck5osqMiipu0Meta?.name ?? "healthcheck___en",
    path: healthcheck5osqMiipu0Meta?.path ?? "/en/healthcheck",
    meta: healthcheck5osqMiipu0Meta || {},
    alias: healthcheck5osqMiipu0Meta?.alias || [],
    redirect: healthcheck5osqMiipu0Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: healthcheck5osqMiipu0Meta?.name ?? "healthcheck___fr",
    path: healthcheck5osqMiipu0Meta?.path ?? "/fr/healthcheck",
    meta: healthcheck5osqMiipu0Meta || {},
    alias: healthcheck5osqMiipu0Meta?.alias || [],
    redirect: healthcheck5osqMiipu0Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: healthcheck5osqMiipu0Meta?.name ?? "healthcheck___it",
    path: healthcheck5osqMiipu0Meta?.path ?? "/it/healthcheck",
    meta: healthcheck5osqMiipu0Meta || {},
    alias: healthcheck5osqMiipu0Meta?.alias || [],
    redirect: healthcheck5osqMiipu0Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93gEgcWcJiWdMeta?.name ?? "preview-id___de",
    path: _91_46_46_46id_93gEgcWcJiWdMeta?.path ?? "/preview/:id(.*)*",
    meta: _91_46_46_46id_93gEgcWcJiWdMeta || {},
    alias: _91_46_46_46id_93gEgcWcJiWdMeta?.alias || [],
    redirect: _91_46_46_46id_93gEgcWcJiWdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/preview/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93gEgcWcJiWdMeta?.name ?? "preview-id___en",
    path: _91_46_46_46id_93gEgcWcJiWdMeta?.path ?? "/en/preview/:id(.*)*",
    meta: _91_46_46_46id_93gEgcWcJiWdMeta || {},
    alias: _91_46_46_46id_93gEgcWcJiWdMeta?.alias || [],
    redirect: _91_46_46_46id_93gEgcWcJiWdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/preview/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93gEgcWcJiWdMeta?.name ?? "preview-id___fr",
    path: _91_46_46_46id_93gEgcWcJiWdMeta?.path ?? "/fr/preview/:id(.*)*",
    meta: _91_46_46_46id_93gEgcWcJiWdMeta || {},
    alias: _91_46_46_46id_93gEgcWcJiWdMeta?.alias || [],
    redirect: _91_46_46_46id_93gEgcWcJiWdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/preview/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93gEgcWcJiWdMeta?.name ?? "preview-id___it",
    path: _91_46_46_46id_93gEgcWcJiWdMeta?.path ?? "/it/preview/:id(.*)*",
    meta: _91_46_46_46id_93gEgcWcJiWdMeta || {},
    alias: _91_46_46_46id_93gEgcWcJiWdMeta?.alias || [],
    redirect: _91_46_46_46id_93gEgcWcJiWdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/preview/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HvA7ftD3JTMeta?.name ?? "registration-conference-id___de",
    path: _91id_93HvA7ftD3JTMeta?.path ?? "/registration/conference/:id()",
    meta: _91id_93HvA7ftD3JTMeta || {},
    alias: _91id_93HvA7ftD3JTMeta?.alias || [],
    redirect: _91id_93HvA7ftD3JTMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/conference/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HvA7ftD3JTMeta?.name ?? "registration-conference-id___en",
    path: _91id_93HvA7ftD3JTMeta?.path ?? "/en/registration/conference/:id()",
    meta: _91id_93HvA7ftD3JTMeta || {},
    alias: _91id_93HvA7ftD3JTMeta?.alias || [],
    redirect: _91id_93HvA7ftD3JTMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/conference/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HvA7ftD3JTMeta?.name ?? "registration-conference-id___fr",
    path: _91id_93HvA7ftD3JTMeta?.path ?? "/fr/registration/conference/:id()",
    meta: _91id_93HvA7ftD3JTMeta || {},
    alias: _91id_93HvA7ftD3JTMeta?.alias || [],
    redirect: _91id_93HvA7ftD3JTMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/conference/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HvA7ftD3JTMeta?.name ?? "registration-conference-id___it",
    path: _91id_93HvA7ftD3JTMeta?.path ?? "/it/registration/conference/:id()",
    meta: _91id_93HvA7ftD3JTMeta || {},
    alias: _91id_93HvA7ftD3JTMeta?.alias || [],
    redirect: _91id_93HvA7ftD3JTMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/conference/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93WuzS8DYIkNMeta?.name ?? "registration-module-id___de",
    path: _91id_93WuzS8DYIkNMeta?.path ?? "/registration/module/:id()",
    meta: _91id_93WuzS8DYIkNMeta || {},
    alias: _91id_93WuzS8DYIkNMeta?.alias || [],
    redirect: _91id_93WuzS8DYIkNMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/module/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93WuzS8DYIkNMeta?.name ?? "registration-module-id___en",
    path: _91id_93WuzS8DYIkNMeta?.path ?? "/en/registration/module/:id()",
    meta: _91id_93WuzS8DYIkNMeta || {},
    alias: _91id_93WuzS8DYIkNMeta?.alias || [],
    redirect: _91id_93WuzS8DYIkNMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/module/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93WuzS8DYIkNMeta?.name ?? "registration-module-id___fr",
    path: _91id_93WuzS8DYIkNMeta?.path ?? "/fr/registration/module/:id()",
    meta: _91id_93WuzS8DYIkNMeta || {},
    alias: _91id_93WuzS8DYIkNMeta?.alias || [],
    redirect: _91id_93WuzS8DYIkNMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/module/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93WuzS8DYIkNMeta?.name ?? "registration-module-id___it",
    path: _91id_93WuzS8DYIkNMeta?.path ?? "/it/registration/module/:id()",
    meta: _91id_93WuzS8DYIkNMeta || {},
    alias: _91id_93WuzS8DYIkNMeta?.alias || [],
    redirect: _91id_93WuzS8DYIkNMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/module/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93tw3bE7BUTFMeta?.name ?? "registration-seminar-all___de",
    path: _91_46_46_46all_93tw3bE7BUTFMeta?.path ?? "/registration/seminar/:all(.*)*",
    meta: _91_46_46_46all_93tw3bE7BUTFMeta || {},
    alias: _91_46_46_46all_93tw3bE7BUTFMeta?.alias || [],
    redirect: _91_46_46_46all_93tw3bE7BUTFMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/seminar/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93tw3bE7BUTFMeta?.name ?? "registration-seminar-all___en",
    path: _91_46_46_46all_93tw3bE7BUTFMeta?.path ?? "/en/registration/seminar/:all(.*)*",
    meta: _91_46_46_46all_93tw3bE7BUTFMeta || {},
    alias: _91_46_46_46all_93tw3bE7BUTFMeta?.alias || [],
    redirect: _91_46_46_46all_93tw3bE7BUTFMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/seminar/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93tw3bE7BUTFMeta?.name ?? "registration-seminar-all___fr",
    path: _91_46_46_46all_93tw3bE7BUTFMeta?.path ?? "/fr/registration/seminar/:all(.*)*",
    meta: _91_46_46_46all_93tw3bE7BUTFMeta || {},
    alias: _91_46_46_46all_93tw3bE7BUTFMeta?.alias || [],
    redirect: _91_46_46_46all_93tw3bE7BUTFMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/seminar/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93tw3bE7BUTFMeta?.name ?? "registration-seminar-all___it",
    path: _91_46_46_46all_93tw3bE7BUTFMeta?.path ?? "/it/registration/seminar/:all(.*)*",
    meta: _91_46_46_46all_93tw3bE7BUTFMeta || {},
    alias: _91_46_46_46all_93tw3bE7BUTFMeta?.alias || [],
    redirect: _91_46_46_46all_93tw3bE7BUTFMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/registration/seminar/[...all].vue").then(m => m.default || m)
  },
  {
    name: searchtAu4maexkaMeta?.name ?? "search___de",
    path: searchtAu4maexkaMeta?.path ?? "/search",
    meta: searchtAu4maexkaMeta || {},
    alias: searchtAu4maexkaMeta?.alias || [],
    redirect: searchtAu4maexkaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchtAu4maexkaMeta?.name ?? "search___en",
    path: searchtAu4maexkaMeta?.path ?? "/en/search",
    meta: searchtAu4maexkaMeta || {},
    alias: searchtAu4maexkaMeta?.alias || [],
    redirect: searchtAu4maexkaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchtAu4maexkaMeta?.name ?? "search___fr",
    path: searchtAu4maexkaMeta?.path ?? "/fr/search",
    meta: searchtAu4maexkaMeta || {},
    alias: searchtAu4maexkaMeta?.alias || [],
    redirect: searchtAu4maexkaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchtAu4maexkaMeta?.name ?? "search___it",
    path: searchtAu4maexkaMeta?.path ?? "/it/search",
    meta: searchtAu4maexkaMeta || {},
    alias: searchtAu4maexkaMeta?.alias || [],
    redirect: searchtAu4maexkaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jFjHtL6hBMMeta?.name ?? "shop-slug___de",
    path: _91_46_46_46slug_93jFjHtL6hBMMeta?.path ?? "/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93jFjHtL6hBMMeta || {},
    alias: _91_46_46_46slug_93jFjHtL6hBMMeta?.alias || [],
    redirect: _91_46_46_46slug_93jFjHtL6hBMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jFjHtL6hBMMeta?.name ?? "shop-slug___en",
    path: _91_46_46_46slug_93jFjHtL6hBMMeta?.path ?? "/en/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93jFjHtL6hBMMeta || {},
    alias: _91_46_46_46slug_93jFjHtL6hBMMeta?.alias || [],
    redirect: _91_46_46_46slug_93jFjHtL6hBMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jFjHtL6hBMMeta?.name ?? "shop-slug___fr",
    path: _91_46_46_46slug_93jFjHtL6hBMMeta?.path ?? "/fr/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93jFjHtL6hBMMeta || {},
    alias: _91_46_46_46slug_93jFjHtL6hBMMeta?.alias || [],
    redirect: _91_46_46_46slug_93jFjHtL6hBMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jFjHtL6hBMMeta?.name ?? "shop-slug___it",
    path: _91_46_46_46slug_93jFjHtL6hBMMeta?.path ?? "/it/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93jFjHtL6hBMMeta || {},
    alias: _91_46_46_46slug_93jFjHtL6hBMMeta?.alias || [],
    redirect: _91_46_46_46slug_93jFjHtL6hBMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: addressesGii3MgdQREMeta?.name ?? "shop-account-addresses___de",
    path: addressesGii3MgdQREMeta?.path ?? "/shop/account/addresses",
    meta: addressesGii3MgdQREMeta || {},
    alias: addressesGii3MgdQREMeta?.alias || [],
    redirect: addressesGii3MgdQREMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesGii3MgdQREMeta?.name ?? "shop-account-addresses___en",
    path: addressesGii3MgdQREMeta?.path ?? "/en/shop/account/addresses",
    meta: addressesGii3MgdQREMeta || {},
    alias: addressesGii3MgdQREMeta?.alias || [],
    redirect: addressesGii3MgdQREMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesGii3MgdQREMeta?.name ?? "shop-account-addresses___fr",
    path: addressesGii3MgdQREMeta?.path ?? "/fr/shop/account/addresses",
    meta: addressesGii3MgdQREMeta || {},
    alias: addressesGii3MgdQREMeta?.alias || [],
    redirect: addressesGii3MgdQREMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesGii3MgdQREMeta?.name ?? "shop-account-addresses___it",
    path: addressesGii3MgdQREMeta?.path ?? "/it/shop/account/addresses",
    meta: addressesGii3MgdQREMeta || {},
    alias: addressesGii3MgdQREMeta?.alias || [],
    redirect: addressesGii3MgdQREMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: indexkBojIwOurAMeta?.name ?? "shop-account___de",
    path: indexkBojIwOurAMeta?.path ?? "/shop/account",
    meta: indexkBojIwOurAMeta || {},
    alias: indexkBojIwOurAMeta?.alias || [],
    redirect: indexkBojIwOurAMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexkBojIwOurAMeta?.name ?? "shop-account___en",
    path: indexkBojIwOurAMeta?.path ?? "/en/shop/account",
    meta: indexkBojIwOurAMeta || {},
    alias: indexkBojIwOurAMeta?.alias || [],
    redirect: indexkBojIwOurAMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexkBojIwOurAMeta?.name ?? "shop-account___fr",
    path: indexkBojIwOurAMeta?.path ?? "/fr/shop/account",
    meta: indexkBojIwOurAMeta || {},
    alias: indexkBojIwOurAMeta?.alias || [],
    redirect: indexkBojIwOurAMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexkBojIwOurAMeta?.name ?? "shop-account___it",
    path: indexkBojIwOurAMeta?.path ?? "/it/shop/account",
    meta: indexkBojIwOurAMeta || {},
    alias: indexkBojIwOurAMeta?.alias || [],
    redirect: indexkBojIwOurAMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93CBWMBdsza2Meta?.name ?? "shop-account-orders-id___de",
    path: _91_46_46_46id_93CBWMBdsza2Meta?.path ?? "/shop/account/orders/:id(.*)*",
    meta: _91_46_46_46id_93CBWMBdsza2Meta || {},
    alias: _91_46_46_46id_93CBWMBdsza2Meta?.alias || [],
    redirect: _91_46_46_46id_93CBWMBdsza2Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93CBWMBdsza2Meta?.name ?? "shop-account-orders-id___en",
    path: _91_46_46_46id_93CBWMBdsza2Meta?.path ?? "/en/shop/account/orders/:id(.*)*",
    meta: _91_46_46_46id_93CBWMBdsza2Meta || {},
    alias: _91_46_46_46id_93CBWMBdsza2Meta?.alias || [],
    redirect: _91_46_46_46id_93CBWMBdsza2Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93CBWMBdsza2Meta?.name ?? "shop-account-orders-id___fr",
    path: _91_46_46_46id_93CBWMBdsza2Meta?.path ?? "/fr/shop/account/orders/:id(.*)*",
    meta: _91_46_46_46id_93CBWMBdsza2Meta || {},
    alias: _91_46_46_46id_93CBWMBdsza2Meta?.alias || [],
    redirect: _91_46_46_46id_93CBWMBdsza2Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93CBWMBdsza2Meta?.name ?? "shop-account-orders-id___it",
    path: _91_46_46_46id_93CBWMBdsza2Meta?.path ?? "/it/shop/account/orders/:id(.*)*",
    meta: _91_46_46_46id_93CBWMBdsza2Meta || {},
    alias: _91_46_46_46id_93CBWMBdsza2Meta?.alias || [],
    redirect: _91_46_46_46id_93CBWMBdsza2Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/[...id].vue").then(m => m.default || m)
  },
  {
    name: indexjJhgTQJMyXMeta?.name ?? "shop-account-orders___de",
    path: indexjJhgTQJMyXMeta?.path ?? "/shop/account/orders",
    meta: indexjJhgTQJMyXMeta || {},
    alias: indexjJhgTQJMyXMeta?.alias || [],
    redirect: indexjJhgTQJMyXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexjJhgTQJMyXMeta?.name ?? "shop-account-orders___en",
    path: indexjJhgTQJMyXMeta?.path ?? "/en/shop/account/orders",
    meta: indexjJhgTQJMyXMeta || {},
    alias: indexjJhgTQJMyXMeta?.alias || [],
    redirect: indexjJhgTQJMyXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexjJhgTQJMyXMeta?.name ?? "shop-account-orders___fr",
    path: indexjJhgTQJMyXMeta?.path ?? "/fr/shop/account/orders",
    meta: indexjJhgTQJMyXMeta || {},
    alias: indexjJhgTQJMyXMeta?.alias || [],
    redirect: indexjJhgTQJMyXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexjJhgTQJMyXMeta?.name ?? "shop-account-orders___it",
    path: indexjJhgTQJMyXMeta?.path ?? "/it/shop/account/orders",
    meta: indexjJhgTQJMyXMeta || {},
    alias: indexjJhgTQJMyXMeta?.alias || [],
    redirect: indexjJhgTQJMyXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payment55xEq6C3FiMeta?.name ?? "shop-account-payment___de",
    path: payment55xEq6C3FiMeta?.path ?? "/shop/account/payment",
    meta: payment55xEq6C3FiMeta || {},
    alias: payment55xEq6C3FiMeta?.alias || [],
    redirect: payment55xEq6C3FiMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: payment55xEq6C3FiMeta?.name ?? "shop-account-payment___en",
    path: payment55xEq6C3FiMeta?.path ?? "/en/shop/account/payment",
    meta: payment55xEq6C3FiMeta || {},
    alias: payment55xEq6C3FiMeta?.alias || [],
    redirect: payment55xEq6C3FiMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: payment55xEq6C3FiMeta?.name ?? "shop-account-payment___fr",
    path: payment55xEq6C3FiMeta?.path ?? "/fr/shop/account/payment",
    meta: payment55xEq6C3FiMeta || {},
    alias: payment55xEq6C3FiMeta?.alias || [],
    redirect: payment55xEq6C3FiMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: payment55xEq6C3FiMeta?.name ?? "shop-account-payment___it",
    path: payment55xEq6C3FiMeta?.path ?? "/it/shop/account/payment",
    meta: payment55xEq6C3FiMeta || {},
    alias: payment55xEq6C3FiMeta?.alias || [],
    redirect: payment55xEq6C3FiMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profileMJiDVBQkIwMeta?.name ?? "shop-account-profile___de",
    path: profileMJiDVBQkIwMeta?.path ?? "/shop/account/profile",
    meta: profileMJiDVBQkIwMeta || {},
    alias: profileMJiDVBQkIwMeta?.alias || [],
    redirect: profileMJiDVBQkIwMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMJiDVBQkIwMeta?.name ?? "shop-account-profile___en",
    path: profileMJiDVBQkIwMeta?.path ?? "/en/shop/account/profile",
    meta: profileMJiDVBQkIwMeta || {},
    alias: profileMJiDVBQkIwMeta?.alias || [],
    redirect: profileMJiDVBQkIwMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMJiDVBQkIwMeta?.name ?? "shop-account-profile___fr",
    path: profileMJiDVBQkIwMeta?.path ?? "/fr/shop/account/profile",
    meta: profileMJiDVBQkIwMeta || {},
    alias: profileMJiDVBQkIwMeta?.alias || [],
    redirect: profileMJiDVBQkIwMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMJiDVBQkIwMeta?.name ?? "shop-account-profile___it",
    path: profileMJiDVBQkIwMeta?.path ?? "/it/shop/account/profile",
    meta: profileMJiDVBQkIwMeta || {},
    alias: profileMJiDVBQkIwMeta?.alias || [],
    redirect: profileMJiDVBQkIwMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: recoveryV5t4Aa0ptkMeta?.name ?? "shop-account-recovery___de",
    path: recoveryV5t4Aa0ptkMeta?.path ?? "/shop/account/recovery",
    meta: recoveryV5t4Aa0ptkMeta || {},
    alias: recoveryV5t4Aa0ptkMeta?.alias || [],
    redirect: recoveryV5t4Aa0ptkMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryV5t4Aa0ptkMeta?.name ?? "shop-account-recovery___en",
    path: recoveryV5t4Aa0ptkMeta?.path ?? "/en/shop/account/recovery",
    meta: recoveryV5t4Aa0ptkMeta || {},
    alias: recoveryV5t4Aa0ptkMeta?.alias || [],
    redirect: recoveryV5t4Aa0ptkMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryV5t4Aa0ptkMeta?.name ?? "shop-account-recovery___fr",
    path: recoveryV5t4Aa0ptkMeta?.path ?? "/fr/shop/account/recovery",
    meta: recoveryV5t4Aa0ptkMeta || {},
    alias: recoveryV5t4Aa0ptkMeta?.alias || [],
    redirect: recoveryV5t4Aa0ptkMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recoveryV5t4Aa0ptkMeta?.name ?? "shop-account-recovery___it",
    path: recoveryV5t4Aa0ptkMeta?.path ?? "/it/shop/account/recovery",
    meta: recoveryV5t4Aa0ptkMeta || {},
    alias: recoveryV5t4Aa0ptkMeta?.alias || [],
    redirect: recoveryV5t4Aa0ptkMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: cart9lyjLQlHAeMeta?.name ?? "shop-cart___de",
    path: cart9lyjLQlHAeMeta?.path ?? "/shop/cart",
    meta: cart9lyjLQlHAeMeta || {},
    alias: cart9lyjLQlHAeMeta?.alias || [],
    redirect: cart9lyjLQlHAeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/cart.vue").then(m => m.default || m)
  },
  {
    name: cart9lyjLQlHAeMeta?.name ?? "shop-cart___en",
    path: cart9lyjLQlHAeMeta?.path ?? "/en/shop/cart",
    meta: cart9lyjLQlHAeMeta || {},
    alias: cart9lyjLQlHAeMeta?.alias || [],
    redirect: cart9lyjLQlHAeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/cart.vue").then(m => m.default || m)
  },
  {
    name: cart9lyjLQlHAeMeta?.name ?? "shop-cart___fr",
    path: cart9lyjLQlHAeMeta?.path ?? "/fr/shop/cart",
    meta: cart9lyjLQlHAeMeta || {},
    alias: cart9lyjLQlHAeMeta?.alias || [],
    redirect: cart9lyjLQlHAeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/cart.vue").then(m => m.default || m)
  },
  {
    name: cart9lyjLQlHAeMeta?.name ?? "shop-cart___it",
    path: cart9lyjLQlHAeMeta?.path ?? "/it/shop/cart",
    meta: cart9lyjLQlHAeMeta || {},
    alias: cart9lyjLQlHAeMeta?.alias || [],
    redirect: cart9lyjLQlHAeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/cart.vue").then(m => m.default || m)
  },
  {
    name: indexgj9RPAfLx1Meta?.name ?? "shop-checkout___de",
    path: indexgj9RPAfLx1Meta?.path ?? "/shop/checkout",
    meta: indexgj9RPAfLx1Meta || {},
    alias: indexgj9RPAfLx1Meta?.alias || [],
    redirect: indexgj9RPAfLx1Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexgj9RPAfLx1Meta?.name ?? "shop-checkout___en",
    path: indexgj9RPAfLx1Meta?.path ?? "/en/shop/checkout",
    meta: indexgj9RPAfLx1Meta || {},
    alias: indexgj9RPAfLx1Meta?.alias || [],
    redirect: indexgj9RPAfLx1Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexgj9RPAfLx1Meta?.name ?? "shop-checkout___fr",
    path: indexgj9RPAfLx1Meta?.path ?? "/fr/shop/checkout",
    meta: indexgj9RPAfLx1Meta || {},
    alias: indexgj9RPAfLx1Meta?.alias || [],
    redirect: indexgj9RPAfLx1Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexgj9RPAfLx1Meta?.name ?? "shop-checkout___it",
    path: indexgj9RPAfLx1Meta?.path ?? "/it/shop/checkout",
    meta: indexgj9RPAfLx1Meta || {},
    alias: indexgj9RPAfLx1Meta?.alias || [],
    redirect: indexgj9RPAfLx1Meta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexL8InZPvytEMeta?.name ?? "shop-checkout-order-id___de",
    path: indexL8InZPvytEMeta?.path ?? "/shop/checkout/order/:id()",
    meta: indexL8InZPvytEMeta || {},
    alias: indexL8InZPvytEMeta?.alias || [],
    redirect: indexL8InZPvytEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL8InZPvytEMeta?.name ?? "shop-checkout-order-id___en",
    path: indexL8InZPvytEMeta?.path ?? "/en/shop/checkout/order/:id()",
    meta: indexL8InZPvytEMeta || {},
    alias: indexL8InZPvytEMeta?.alias || [],
    redirect: indexL8InZPvytEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL8InZPvytEMeta?.name ?? "shop-checkout-order-id___fr",
    path: indexL8InZPvytEMeta?.path ?? "/fr/shop/checkout/order/:id()",
    meta: indexL8InZPvytEMeta || {},
    alias: indexL8InZPvytEMeta?.alias || [],
    redirect: indexL8InZPvytEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL8InZPvytEMeta?.name ?? "shop-checkout-order-id___it",
    path: indexL8InZPvytEMeta?.path ?? "/it/shop/checkout/order/:id()",
    meta: indexL8InZPvytEMeta || {},
    alias: indexL8InZPvytEMeta?.alias || [],
    redirect: indexL8InZPvytEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/checkout/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1MFfh1bNmMeta?.name ?? "shop___de",
    path: indexQ1MFfh1bNmMeta?.path ?? "/shop",
    meta: indexQ1MFfh1bNmMeta || {},
    alias: indexQ1MFfh1bNmMeta?.alias || [],
    redirect: indexQ1MFfh1bNmMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1MFfh1bNmMeta?.name ?? "shop___en",
    path: indexQ1MFfh1bNmMeta?.path ?? "/en/shop",
    meta: indexQ1MFfh1bNmMeta || {},
    alias: indexQ1MFfh1bNmMeta?.alias || [],
    redirect: indexQ1MFfh1bNmMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1MFfh1bNmMeta?.name ?? "shop___fr",
    path: indexQ1MFfh1bNmMeta?.path ?? "/fr/shop",
    meta: indexQ1MFfh1bNmMeta || {},
    alias: indexQ1MFfh1bNmMeta?.alias || [],
    redirect: indexQ1MFfh1bNmMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1MFfh1bNmMeta?.name ?? "shop___it",
    path: indexQ1MFfh1bNmMeta?.path ?? "/it/shop",
    meta: indexQ1MFfh1bNmMeta || {},
    alias: indexQ1MFfh1bNmMeta?.alias || [],
    redirect: indexQ1MFfh1bNmMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: loginm0S2q7JMiMMeta?.name ?? "shop-login___de",
    path: loginm0S2q7JMiMMeta?.path ?? "/shop/login",
    meta: loginm0S2q7JMiMMeta || {},
    alias: loginm0S2q7JMiMMeta?.alias || [],
    redirect: loginm0S2q7JMiMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/login.vue").then(m => m.default || m)
  },
  {
    name: loginm0S2q7JMiMMeta?.name ?? "shop-login___en",
    path: loginm0S2q7JMiMMeta?.path ?? "/en/shop/login",
    meta: loginm0S2q7JMiMMeta || {},
    alias: loginm0S2q7JMiMMeta?.alias || [],
    redirect: loginm0S2q7JMiMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/login.vue").then(m => m.default || m)
  },
  {
    name: loginm0S2q7JMiMMeta?.name ?? "shop-login___fr",
    path: loginm0S2q7JMiMMeta?.path ?? "/fr/shop/login",
    meta: loginm0S2q7JMiMMeta || {},
    alias: loginm0S2q7JMiMMeta?.alias || [],
    redirect: loginm0S2q7JMiMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/login.vue").then(m => m.default || m)
  },
  {
    name: loginm0S2q7JMiMMeta?.name ?? "shop-login___it",
    path: loginm0S2q7JMiMMeta?.path ?? "/it/shop/login",
    meta: loginm0S2q7JMiMMeta || {},
    alias: loginm0S2q7JMiMMeta?.alias || [],
    redirect: loginm0S2q7JMiMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/login.vue").then(m => m.default || m)
  },
  {
    name: register9LJ5bkmlgdMeta?.name ?? "shop-register___de",
    path: register9LJ5bkmlgdMeta?.path ?? "/shop/register",
    meta: register9LJ5bkmlgdMeta || {},
    alias: register9LJ5bkmlgdMeta?.alias || [],
    redirect: register9LJ5bkmlgdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/register.vue").then(m => m.default || m)
  },
  {
    name: register9LJ5bkmlgdMeta?.name ?? "shop-register___en",
    path: register9LJ5bkmlgdMeta?.path ?? "/en/shop/register",
    meta: register9LJ5bkmlgdMeta || {},
    alias: register9LJ5bkmlgdMeta?.alias || [],
    redirect: register9LJ5bkmlgdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/register.vue").then(m => m.default || m)
  },
  {
    name: register9LJ5bkmlgdMeta?.name ?? "shop-register___fr",
    path: register9LJ5bkmlgdMeta?.path ?? "/fr/shop/register",
    meta: register9LJ5bkmlgdMeta || {},
    alias: register9LJ5bkmlgdMeta?.alias || [],
    redirect: register9LJ5bkmlgdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/register.vue").then(m => m.default || m)
  },
  {
    name: register9LJ5bkmlgdMeta?.name ?? "shop-register___it",
    path: register9LJ5bkmlgdMeta?.path ?? "/it/shop/register",
    meta: register9LJ5bkmlgdMeta || {},
    alias: register9LJ5bkmlgdMeta?.alias || [],
    redirect: register9LJ5bkmlgdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/register.vue").then(m => m.default || m)
  },
  {
    name: searchfwjFqkC70VMeta?.name ?? "shop-search___de",
    path: searchfwjFqkC70VMeta?.path ?? "/shop/search",
    meta: searchfwjFqkC70VMeta || {},
    alias: searchfwjFqkC70VMeta?.alias || [],
    redirect: searchfwjFqkC70VMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: searchfwjFqkC70VMeta?.name ?? "shop-search___en",
    path: searchfwjFqkC70VMeta?.path ?? "/en/shop/search",
    meta: searchfwjFqkC70VMeta || {},
    alias: searchfwjFqkC70VMeta?.alias || [],
    redirect: searchfwjFqkC70VMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: searchfwjFqkC70VMeta?.name ?? "shop-search___fr",
    path: searchfwjFqkC70VMeta?.path ?? "/fr/shop/search",
    meta: searchfwjFqkC70VMeta || {},
    alias: searchfwjFqkC70VMeta?.alias || [],
    redirect: searchfwjFqkC70VMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: searchfwjFqkC70VMeta?.name ?? "shop-search___it",
    path: searchfwjFqkC70VMeta?.path ?? "/it/shop/search",
    meta: searchfwjFqkC70VMeta || {},
    alias: searchfwjFqkC70VMeta?.alias || [],
    redirect: searchfwjFqkC70VMeta?.redirect || undefined,
    component: () => import("/usr/src/app/src/pages/shop/search.vue").then(m => m.default || m)
  }
]