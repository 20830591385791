import { Clusters } from './Clusters'
import { Queue } from './Queue'

export class Animation {
  private clusters: Clusters
  private observer: IntersectionObserver
  private queue: Queue
  constructor() {
    this.clusters = new Clusters()
    this.queue = new Queue([], 100)

    this.handleTargetsInViewport = this.handleTargetsInViewport.bind(this)

    const observerOptions = {
      root: null,
      rootMargin: '0px',
    }

    this.observer = new IntersectionObserver(this.handleTargetsInViewport, observerOptions)
  }

  private handleTargetsInViewport(entries: IntersectionObserverEntry[]) {
    const targetsInViewport: HTMLElement[] = entries
      .filter((entry) => entry.isIntersecting)
      .map((entry) => {
        this.observer.unobserve(entry.target)
        return entry.target as HTMLElement
      })

    this.queue = new Queue(this.clusters.getItemsToAnimate(targetsInViewport), 150)
    this.queue.process()
  }

  public add(element: HTMLElement) {
    const target: HTMLElement | undefined = this.clusters.addElement(element)

    if (target) {
      this.observer.observe(target)
    }
  }

  public remove(element: HTMLElement) {
    const target: HTMLElement | undefined = this.clusters.removeElement(element)

    if (target) {
      this.observer.unobserve(target)
    }
  }
}
