<script setup lang="ts">
import { MediaImageFragment } from './api/graphql/generated'

const error = useError()
const { t } = useI18n()

const errorMessages: Ref<{ [key: number]: string }> = computed(() => ({
  404: t('error.404'),
  408: t('error.408'),
  500: t('error.500'),
  502: t('error.502'),
  503: t('error.503'),
}))

const handleError = () => {
  clearError({
    redirect: '/',
  })
}

const image = { fieldMediaImage: { url: '/img/page-not-found.png', height: 600, width: 100 } } as MediaImageFragment
const statusCode = ref((error.value as any)?.statusCode || 404)
</script>

<template>
  <NuxtLayout name="default">
    <Section class="section-404">
      <div class="background" />
      <div class="tl-5 tl-o-1">
        <h1>{{ statusCode }} - {{ errorMessages[statusCode] }}</h1>

        <Button secondary slide icon="arrow-left" @click="handleError">
          {{ $t('error.ctaTitle') }}
        </Button>
      </div>
      <div class="tl-6 desktop-only">
        <Image :entity="image" type="teaser" />
      </div>
    </Section>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.section-404 {
  position: relative;

  padding: 100px 24px;

  align-items: center;

  @include breakpoint(tl) {
    min-height: 800px;
    padding: 100px 80px;
  }
}
.background {
  @include fill;
  left: 50%;
  translate: -50%;
  z-index: -1;

  background: var(--c-blue-light-60);
}

.dp-image {
  display: block;
  margin: 0 auto;
}
</style>
