export const transitionEndEvent: any = (() => {
  if (process.client) {
    const el: HTMLElement = document.createElement('fakeelement')

    const transitions = {
      transition: 'transitionend',
      OTransition: 'otransitionend',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
    }

    let x: keyof typeof transitions
    for (x in transitions) {
      if (el.style[x as keyof CSSStyleDeclaration] !== undefined) {
        return transitions[x]
      }
    }
  }

  return undefined
})()

export const getTransitionDuration = (element: HTMLElement) => {
  let res = 0

  prefix('transition-duration', function (v: any) {
    const durations = window.getComputedStyle(element)[v]
    if (!durations) return

    res = Math.max(...durations.split(',').map((duration) => parseTime(duration)))

    function parseTime(s: string) {
      return parseFloat(s) * (s.includes('ms') ? 1 : 1000)
    }
  })

  return res

  function prefix(str: string, cb: Function, prefixes = '') {
    prefixes = ' ' + (prefixes || 'webkit moz ms o khtml')
    prefixes.split(' ').some(function (v) {
      const prefix = v ? '-' + v : v
      cb(pfx(str), pfx)
      function pfx(s: string) {
        return camelCase(prefix + s)
      }
    })
    function camelCase(s: string) {
      return s.toLowerCase().replace(/-(.)/g, function (_, m: string) {
        return m.toUpperCase()
      })
    }
  }
}

interface Rect {
  x: number
  y: number
  w: number
  h: number
}
const intersection = (r1: Rect, r2: Rect) => {
  const xOverlap = Math.max(0, Math.min(r1.x + r1.w, r2.x + r2.w) - Math.max(r1.x, r2.x))
  const yOverlap = Math.max(0, Math.min(r1.y + r1.h, r2.y + r2.h) - Math.max(r1.y, r2.y))
  const overlapArea = xOverlap * yOverlap

  return overlapArea
}

const percentInView = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()

  const dimension = { x: rect.x, y: rect.y, w: rect.width, h: rect.height }
  const viewport = { x: 0, y: 0, w: window.innerWidth, h: window.innerHeight }
  const size = dimension.w * dimension.h
  const overlap = intersection(dimension, viewport)

  return overlap / size
}

export const isInViewport = (element: HTMLElement) => percentInView(element) > 0
