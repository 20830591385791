<script lang="ts" setup>
import { IconName } from '../icon/Icon.vue'
import { ButtonBaseProps } from './ButtonBase.vue'

interface ButtonProps extends ButtonBaseProps {
  primary?: boolean
  secondary?: boolean
  icon?: IconName
  slide?: boolean
}
const props = defineProps<ButtonProps>()

const icon = computed(() => props.icon || (props.slide ? 'arrow-left' : undefined))
</script>

<template>
  <ButtonBase
    :class="['label', { primary }, { secondary }, { icon }, { slide }, { 'slide-right': slide }]"
    v-bind="props"
  >
    <template v-if="icon" #before>
      <Icon :name="icon" />
    </template>
    <slot />
    <template v-if="icon && slide" #after>
      <Icon :name="icon" />
    </template>
  </ButtonBase>
</template>

<style lang="scss" scoped>
.button {
  &.primary {
    --color: var(--c-white);
    --color-border: var(--c-primary);
    --color-background: var(--c-primary);
    --color-background-hover: transparent;

    @include fluid-ts-ds(--pv, 22px, 29px);
    @include fluid-ts-ds(--ph, 24px, 40px);
  }
  &.secondary {
    --color-border: var(--c-secondary);
    --color-background: var(--c-secondary);
    --color-border-hover: var(--c-background-strong);
    --color-background-hover: var(--c-background-strong);
  }
  &.icon:not(.slide) {
    --padding: calc(var(--icon-size) + var(--gap));
    :deep(.text) {
      padding-left: var(--padding);
    }
    :deep(svg:first-child) {
      position: absolute;
      top: 50%;
      left: 0;
      translate: 0 -50%;
    }
  }
}
</style>
