import { Category } from '@shopware-pwa/types'
import { getShopPermalink, getCategoryPermalinkByForeignKey } from '~/lib/'
import { Link } from '~/types'

export const useShopBreadcrumbs = (category: Category) => {
  const { t } = useI18n()

  const finalBreadcrumbs = category.breadcrumb.slice(1)
  const currentCategory = finalBreadcrumbs.pop() ?? ''
  const finalPaths = category.path?.split('|').filter(Boolean).slice(1) || []

  const breadcrumbs: Link[] = [
    {
      text: t('shop.breadcrumbs.home'),
      url: '/',
    },
    {
      text: t('shop.breadcrumbs.shop'),
      url: getShopPermalink(),
    },
    ...finalBreadcrumbs.map((breadcrumb, index) => ({
      text: breadcrumb,
      url: getCategoryPermalinkByForeignKey(finalPaths[index]),
    })),
    {
      text: currentCategory,
      url: '',
    },
  ]
  return { breadcrumbs }
}
