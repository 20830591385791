import { set } from 'date-fns'
import './animations.scss'
import { Animation } from './Animmation'
import { isInViewport } from './utils'

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const store = nuxtApp.$pinia as any
    const animation = new Animation()
    nuxtApp.vueApp.directive('animate', {
      created(el, binding) {
        if (binding.value?.cluster) {
          el.dataset.animationCluster = ''
        }
        if (binding.value?.animation) {
          el.dataset.animation = binding.value.animation
        }
        if (binding.value?.animationDelay) {
          el.dataset.animationDelay = binding.value.animationDelay
        }
      },
      mounted(el, binding) {
        const firstLoad = store.state.value.Loading.isLoading === null

        if (isInViewport(el) && firstLoad) return

        if (!binding.value?.animation) return

        animation.add(el)
      },
      beforeUnmount(el, binding) {
        if (binding.value?.animation) {
          animation.remove(el)
        }
      },
    })
  } else {
    nuxtApp.vueApp.directive('animate', {
      getSSRProps(binding) {
        return {
          'data-animation': binding.value.animation,
        }
      },
    })
  }
})
