import validate from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/pages/runtime/validate.js";
import clear_45language_45switcher_45links_45global from "/usr/src/app/src/middleware/clearLanguageSwitcherLinks.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/.pnpm/nuxt@3.8.2_@types+node@18.19.2_eslint@8.55.0_sass@1.69.5_typescript@5.3.2_vite@5.0.6_vue-tsc@1.8.25/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clear_45language_45switcher_45links_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "get-layout": () => import("/usr/src/app/src/middleware/getLayout.ts"),
  "logged-in-redirect": () => import("/usr/src/app/src/middleware/loggedInRedirect.ts")
}