export default [
  {
    nodeId: '173',
    translations: [
      { url: '/shop/service', language: 'en' },
      { url: '/shop/aktualisierungen', language: 'de' },
    ],
    name: 'service',
  },
  {
    nodeId: '221',
    translations: [
      { url: '/shop/bestaetigung-feedback-lehrmittel', language: 'de' },
      { url: '/shop/conferma-feedback-materiali-didattici', language: 'it' },
      { url: '/shop/confirmation-feedback-teaching-material', language: 'en' },
      {
        url: '/shop/confirmation-remarques-supports-didactiques',
        language: 'fr',
      },
    ],
    name: 'confirmation-feedback-teaching-material',
  },
  {
    nodeId: '222',
    translations: [
      { url: '/shop/feedback-lehrmittel', language: 'de' },
      { url: '/shop/feedback-materiali-didattici', language: 'it' },
      { url: '/shop/feedback-teaching-materials', language: 'en' },
      { url: '/shop/remarques-supports-didactiques', language: 'fr' },
    ],
    name: 'feedback-teaching-materials',
  },
  {
    nodeId: '223',
    translations: [
      { url: '/shop/anmeldung-aktualitaetenservice', language: 'de' },
      {
        url: '/shop/inscription-infolettre-editions-careum',
        language: 'fr',
      },
      { url: '/shop/newsletter-registration', language: 'en' },
      { url: '/shop/registrazione-servizio-aggiornamento', language: 'it' },
    ],
    name: 'newsletter-registration',
  },
  {
    nodeId: '224',
    translations: [
      { url: '/shop/bestaetigung-aktualitaetenservice', language: 'de' },
      {
        url: '/shop/conferma-registrazione-servizio-aggiornamento',
        language: 'it',
      },
      {
        url: '/shop/confirmation-infolettre-editions-careum',
        language: 'fr',
      },
      { url: '/shop/confirmation-newsletter', language: 'en' },
    ],
    name: 'confirmation-newsletter',
  },
  {
    nodeId: '263',
    translations: [
      { url: '/shop/bestaetigung-kontaktanfrage-verlag', language: 'de' },
      { url: '/shop/conferma-contatto-richiesta-editore', language: 'it' },
      { url: '/shop/confirmation-contact', language: 'en' },
      { url: '/shop/confirmation-contact', language: 'fr' },
    ],
    name: 'confirmation-contact',
  },
  {
    nodeId: '642',
    translations: [
      { url: '/shop/contact', language: 'fr' },
      { url: '/shop/contact', language: 'en' },
      { url: '/shop/contatti', language: 'it' },
      { url: '/shop/kontakt', language: 'de' },
    ],
    name: 'contact',
  },
  {
    nodeId: '704',
    translations: [
      { url: '/shop/buchhandel', language: 'de' },
      { url: '/shop/librairies', language: 'fr' },
      { url: '/shop/librerie', language: 'it' },
      { url: '/shop/booksales', language: 'en' },
    ],
    name: 'buchhandel',
  },
  {
    nodeId: '705',
    translations: [
      { url: '/shop/schulen', language: 'de' },
      { url: '/shop/scuole', language: 'it' },
      { url: '/shop/ecoles', language: 'fr' },
      { url: '/shop/schools', language: 'en' },
    ],
    name: 'schulen',
  },
  {
    nodeId: '706',
    translations: [
      { url: '/shop/Lernende', language: 'de' },
      { url: '/shop/personnes-en-formation', language: 'fr' },
      { url: '/shop/persone-in-formazione', language: 'it' },
      { url: '/shop/apprentices', language: 'en' },
    ],
    name: 'Lernende',
  },
  {
    nodeId: '711',
    translations: [{ url: '/shop/newsletter', language: 'de' }],
    name: 'newsletter',
  },
  {
    nodeId: '712',
    translations: [{ url: '/shop/bestaetigung-newsletter', language: 'de' }],
    name: 'bestaetigung-newsletter',
  },
  {
    nodeId: '735',
    translations: [{ url: '/shop/SVA2023', language: 'de' }],
    name: 'SVA2023',
  },
]
